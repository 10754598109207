window.PriceReview = {
  init: function(currentPrice) {
    $('#apartment_advertised_price_per_month').on('input', (e) => {
      var newPrice = parseInt($('#apartment_advertised_price_per_month').val())

      if(newPrice == currentPrice) {
        $('#apartment_review_price').removeClass('disabled');
        $('#apartment_review_price').prop( "disabled", false );
      } else {
        $('#apartment_review_price').prop( "checked", true );
        $('#apartment_review_price').addClass('disabled');
        $('#apartment_review_price').prop( "disabled", true );
      }
    })
  }
}
