// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require('@rails/ujs').start();

// Chartkick: https://chartkick.com/
require("chartkick")
require("chart.js")
import Chartkick from "chartkick"
import Chart from "chart.js"
import Swal from 'sweetalert2'

require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'controllers';
import 'pages/proof_of_income.js';
import 'pages/apartment_price_review.js';
import 'pages/registration.js';
import 'pages/apartment_lease.js';
import 'pages/reminder_email.js';
import 'pages/inspection.js';
import 'pages/parcel_management.js';
import 'pages/newsletter_email.js';
import 'pages/landing_page.js';
import 'pages/view_map.js';
