function resetCaptcha() {
  if (typeof grecaptcha != "undefined") {
    grecaptcha.reset();
  }
}

function createReminderEmail() {
  if (!$("#reminder-email-form").valid()) {
    resetCaptcha();
    return;
  }

  let formData = new FormData($("#reminder-email-form")[0]);
  const Swal = require("sweetalert2");

  $.ajax({
    url: `/reminder_emails`,
    processData: false,
    contentType: false,
    type: "POST",
    data: formData,
    success: function (data) {
      if (data) {
        if (data.error) {
          Swal.fire({ icon: "error", text: data.error });
          resetCaptcha();
        } else {
          $("#email-reminder-section").remove();
          Swal.fire("Your reminder is set successfully.");
        }
      }
    },
    failure: function (error) {
      Swal.fire({ icon: "error", text: error });
      resetCaptcha();
    },
  });
}

function validateReminderForm() {
  $("#reminder-email-form").validate({
    rules: {
      "reminder_email[email]": {
        required: true,
        email: true,
      },
    },
  });
}

function listenToSubmitForLoggedInUser() {
  $("#reminder-email-form").on("submit", function (e) {
    e.preventDefault();
    createReminderEmail();
  });
}

window.ReminderEmail = {
  init: function () {
    validateReminderForm();
    listenToSubmitForLoggedInUser();
  },

  submitForm: function () {
    createReminderEmail();
  },
};
