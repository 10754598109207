function initSearchForm() {
  $('#tenant_ids').select2({
    minimumResultsForSearch: Infinity,
    ajax: {
      url: "/admin/parcel_management_notifications/search_tenants",
      dataType: 'json',
      delay: 250,
      data: function (params) {
        return {
          q: params.term
        };
      },
      processResults: function (data, params) {
        return {
          results: data
        };
      },
      cache: true
    },
    minimumInputLength: 1
  });
}

function handleSubmitForm() {
  const Swal = require('sweetalert2');
  $("#btn-create-notifications").click(function(e) {
    if ($("#tenant_ids").val()) {
      tenant_list = [];
      $(".select2-selection__choice").each(function() {
        tenant_list.push($(this).attr("title"))
      });

      let confirmation = confirm(`Are you sure you want to email and notify ${tenant_list.join(", ")} to collect their package from reception today?`);
      if (!confirmation)
        e.preventDefault();
    }
    else {
      e.preventDefault();
      Swal.fire({ icon: 'error', text: "Please choose a tenant" });
    }
  });
}

window.ParcelManagement = {
  init: function() {
    $(document).ready(function() {
      initSearchForm();
      handleSubmitForm();
    })
  }
}