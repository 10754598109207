window.LandingPage = {
  init: function () {
    if( Cookies.get('first_time_visit') === undefined) {
      $('#chooselandingpage').modal('show');
      Cookies.set('first_time_visit', true);
    }
    $('#one_three_north').click(function() {
      Cookies.set('preference', 'one_three_north')
    });
    $('#two_three_north').click(function() {
      Cookies.set('preference', 'two_three_north')
    });
  }
}

