window.RegistrationPage = {
  captchaWidgetID: null,
  form: null,
  submitButton: null,

  init: function (captchaSiteID) {
    this.captchaWidgetID = grecaptcha.render("captcha-wrapper", {
      callback: () => this.submitForm(),
      sitekey: captchaSiteID,
      size: "invisible",
    });

    this.form = document.querySelector("form[id='new_user']");
    this.submitButton = document.querySelector("#btn-get-started");

    $("#btn-get-started").on("click", () => this.validateForm());

    $("#user_interested_in_one_bed").change(function (e) {
      if ($(e.target).is(":checked")) {
        $("#one-br-modal").modal("show");
      }
    });

    $("#reference-code").on("keypress", function (e) {
      if (e.which == 13) {
        reload_page_with_reference();
      }
    });

    $("#reference-code-button").click(function (e) {
      reload_page_with_reference();
    });

    function reload_page_with_reference() {
      var code = $("#reference-code").val();
      if (code != null) {
        location.href = url_add_parameter(
          location.href,
          "reference",
          code.toUpperCase()
        );
      }
    }

    function url_add_parameter(url, param, value) {
      var hash = {};
      var parser = document.createElement("a");

      parser.href = url;

      var parameters = parser.search.split(/\?|&/);

      for (var i = 0; i < parameters.length; i++) {
        if (!parameters[i]) continue;

        var ary = parameters[i].split("=");
        hash[ary[0]] = ary[1];
      }

      hash[param] = value;

      var list = [];
      Object.keys(hash).forEach(function (key) {
        list.push(key + "=" + hash[key]);
      });

      parser.search = "?" + list.join("&");
      return parser.href;
    }
  },

  validateForm: function () {
    if (!this.form.checkValidity()) {
      this.resetCaptcha();
      this.form.reportValidity();
    } else {
      this.submitButton.disabled = true;
      this.submitButton.classList.add("disabled");

      grecaptcha.execute(this.captchaWidgetID);
    }
  },

  submitForm: function () {
    this.form.submit();
  },

  resetCaptcha: function () {
    if (typeof this.captchaWidgetID != "undefined") {
      grecaptcha.reset(this.captchaWidgetID);
    }
  },
};
