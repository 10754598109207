function resetCaptcha() {
  if (typeof(grecaptcha) != 'undefined') {
    grecaptcha.reset();
  }
}

function createNewsletterEmail() {
  if (!$("#newsletter-email-form").valid()) {
    resetCaptcha();
    return;
  }

  let formData = new FormData($('#newsletter-email-form')[0]);
  const Swal = require('sweetalert2');

  $.ajax({
    url: `/newsletter_emails`,
    processData: false,
    contentType: false,
    type: "POST",
    data: formData,
    success: function(data) {
      if (data) {
        if (data.error) {
          Swal.fire({ icon: 'error', text: data.error });
          resetCaptcha();
        }
      }
    },
    failure: function(error) {
      Swal.fire({ icon: 'error', text: error });
      resetCaptcha();
    }
  });
}


window.NewsletterEmail = {
  
  submitForm: function() {
    createNewsletterEmail();
  }
}
