import { Controller } from "stimulus";

export default class extends Controller {
  static targets =
    ["checkbox"]
    

  initialize() {
    this.setupToggles();
  }

  setupToggles() {
    for (let c of this.checkboxTargets) {
      c.bootstrapToggle();
    }
  }
}
