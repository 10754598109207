$.fn.fileUploader = function (filesToUpload) {
  let reader = new FileReader();

  this.closest(".files").change(function (evt) {
    let output = [];
    let fileIdCounter = 0;
    let roomItemId = $(evt.target).data('item-id');

    if (!filesToUpload[roomItemId])
      filesToUpload[roomItemId] = []

    for (let i = 0; i < evt.target.files.length; i++) {
      fileIdCounter++;
      let file = evt.target.files[i];
      let fileId = roomItemId + '-' + fileIdCounter;

      if (file['type'].split('/')[0] != 'image') {
        alert("File is not valid. Only image files are allowed.")
        return
      }

      if (file.size / (1024 * 1024) > 5) {
        alert("File is too large. Please upload file with maximum size 5MB.")
        return
      }

      filesToUpload[roomItemId].push({
        id: fileId,
        file: file
      });

      let removeLink = "<a class='remove-file close' href='#' data-file-id='" + fileId + "'><i class='fas fa-times'></i></a>";
      let url = URL.createObjectURL(file)
      output.push("<li><img src=", url," height='100' width='100'>", removeLink, "</li> ");
    };

    $(this).children(".file-list")
        .prepend(output.join(""));

    evt.target.value = null;
  });

  $(this).on("click", ".remove-file", function (e) {
    e.preventDefault();
    let roomItemId = $(this).parents(".files").children(".btn-file").children("input").data("item-id")

    let fileId = $(this).data("file-id");

    for (let i = 0; i < filesToUpload[roomItemId].length; ++i) {
      if (filesToUpload[roomItemId][i].id === fileId)
        filesToUpload[roomItemId].splice(i, 1);
    }

    $(this).parent().remove();
  });

  return this;
};

function validateForm() {
  data = $('#inspection-form').serializeArray();
  inspectionType = data.find(element => element.name == "inspection_type");

  if (inspectionType.value == "") {
    alert("Please choose inspection type.");
    return false;
  }

  return true
};

function handleSubmitForm(filesToUpload) {
  let formData = new FormData($('#inspection-form')[0]);
  $.each(filesToUpload, function(inspectableRoomItemId, images) {
    $.each(images, function( index, image ) {
      formData.append(`images[${inspectableRoomItemId}][]`, image.file);
    });
  });

  apartmentId = $("#apartment_id").val()

  if (validateForm()) {
    let confirmSubmit = confirm("Are you sure you want to submit this inspection?");
    if (confirmSubmit) {
      $.blockUI();
      $(window).off('beforeunload');

      $.ajax({
        url: `/admin/apartments/${apartmentId}/inspections`,
        data: formData,
        processData: false,
        contentType: false,
        type: "POST"
      });
    }
  }
}

function initUploadFiles() {
  let filesToUpload = [];
  $(".files").fileUploader(filesToUpload);

  $("#btn-submit-form").click(function(e) {
    e.preventDefault();
    handleSubmitForm(filesToUpload);
  });
}


window.Inspection = {
  init: function() {
    initUploadFiles();

    $(window).bind("beforeunload", function(e) {
      e.returnValue = 'There are unsaved data. Are you sure you want to leave?';
      return e.returnValue;
    });
  }
}
