window.ApartmentLease = {
  init: function() {
    $("#move_in_date").change(function(e) {
      $("#btn-open-lease").attr("disabled", ($(e.target).val() == ""));
    });

    $("#move_in_date").trigger('change');

    $("#reference-code").on("keypress", function (e) {
      if (e.which == 13) {
        reload_page_with_reference();
      }
    });

    $("#reference-code-button").click(function (e) {
      reload_page_with_reference();
    });

    function reload_page_with_reference() {
      var code = $("#reference-code").val();
      if (code != null) {
        location.href = url_add_parameter(
          location.href,
          "reference",
          code.toUpperCase()
        );
      }
    }

    function url_add_parameter(url, param, value) {
      var hash = {};
      var parser = document.createElement("a");

      parser.href = url;

      var parameters = parser.search.split(/\?|&/);

      for (var i = 0; i < parameters.length; i++) {
        if (!parameters[i]) continue;

        var ary = parameters[i].split("=");
        hash[ary[0]] = ary[1];
      }

      hash[param] = value;

      var list = [];
      Object.keys(hash).forEach(function (key) {
        list.push(key + "=" + hash[key]);
      });

      parser.search = "?" + list.join("&");
      return parser.href;
    }
  }
}
